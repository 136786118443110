
.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
  color:white;
  background : #9c158f;
  }

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover {
    color:white;
    background : #9c158f;
    }
.MuiButton-text {
      text-transform: none !important;
    }
/* @keyframes slide-in {
      0% {
        opacity: 0;
        transform: translateY(-50%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    } */


/* .MuiButtonBase-root.MuiMenuItem-root:hover{
    color:white;
    background:#e1bee7
  } */
